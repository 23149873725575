.welcome-section-bg {
    background: #F3F4F6;
}

.welcome-section {
    display: flex;
    justify-content: space-between;
    padding-top: 100px;
}

.welcome-text {
    width: 50%;
}

.welcome-text-title {
    font-size: 36px;
    margin-bottom: 0;
}

.welcome-text-description {
    color: #394150;
    font-size: 18px;
    margin-bottom: 60px;
}

.welcome-text-tracking-link {
    text-decoration: none;
    background: #4E80EE;
    padding: 12px;
    border-radius: 10px;
    color: #fff;
    margin-right: 20px;
}

.welcome-text-tracking-link:hover {
    background: #2563eb;
}

.welcome-text-profile-link {
    text-decoration: none;
    border: 1px #4E80EE solid;
    background: #fff;
    padding: 11px;
    border-radius: 10px;
    color: #4e80ee;
}

.welcome-text-profile-link:hover {
    color: #fff;
    background: #4e80ee;
    border: none;
}

.welcome-image-container {
    width: 50%;
}

.welcome-image {
    width: 100%;
    margin-bottom: 80px;
    border-radius: 10px;
}

.welcome-sign-in-btn {
    text-decoration: none;
    background: #121826;
    padding: 12px;
    border-radius: 10px;
    color: #fff;
    margin-right: 20px;
    align-self: center;
    margin-top: 20px;
}

.welcome-sign-in-btn:hover {
    text-decoration: none;
    color: #fff;
    margin-right: 20px;
    background: #121850;
}

.welcome-sign {
    display: flex;  
    flex-wrap: wrap;
    justify-content: center;
}

.welcome-sign-up-btn {
    text-decoration: none;
    color: #fff;
    background: #4E80EE;
    padding: 12px;
    border-radius: 10px;
    margin-right: 20px;
    margin-top: 20px;
}

.welcome-sign-up-btn:last-child {
}


.welcome-sign-up-btn:hover {
    background: #2563eb;
}