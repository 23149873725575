.details-bg {
    background: #E3E4E6;
    min-height: 80vh;
}

.details-section {
    padding: 80px 0;
}

.details-title {
    font-size: 32px;
}