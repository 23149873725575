.footer-bg {
    background: #121826;
}

.footer {
    padding: 30px 0;
}

.footer-links {
    display: flex;
    justify-content: space-between;
}

.footer-logo {
    display: flex;
    align-items: center;
}

.footer-logo-text {
    color: #fff;
}

.footer-logo-text-link {
    text-decoration: none;
}

.footer-logo-text:hover {
    cursor: pointer;
}

.footer-links-btns {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.footer-links-btn {
    text-decoration: none;
    color: #fff;
    margin: 10px;
    font-size: 18px;
}

.footer-links-contacts {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.footer-links-btn:hover {
    color: #9DA3AE;
}

.footer-links-contacts-link {
    margin-bottom: 20px;
}

.footer-links-contacts-link:hover {
    cursor: pointer;
}

.footer-links-contacts-text {
    color: #fff;
    font-size: 18px;
    margin-left: 5px;
}

.footer-links-contacts-text:hover {
    color: #9DA3AE;
    cursor: pointer;
}

.footer-rights-text {
    font-size: 16px;
    color: #9DA3AE;
    text-align: center;
    font-weight: 300;
}