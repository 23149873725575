.pickup-section {
    padding: 80px 0;
}

.pickup-title {
    margin-bottom: 50px;
    font-size: 28px;
}

.puckup-data {
    display: flex;
    justify-content: space-between;
}

.pickup-data-title {
    border: 1px solid #E6E7EB;
    border-radius: 10px;
    padding: 20px;
    width: 31%;
    text-align: center;
}

.pickup-data-name {
    font-size: 26px;
    margin: 5px 0 15px;
}

.pickup-data-description {
    margin-top: 20px;
}

.pickup-data-link {
    font-size: 24px;
    text-decoration: none;
    color: green;
}