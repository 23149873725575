@media only screen and (max-width: 767.98px) {
    .profile-data-section {
        padding: 0;
    }

    .profile-logo {
        display: none;
    }

    .profile-data {
        width: 100%;
    }

    .profile-data-title {
        font-size: 24px;
    }
}