@media only screen and (max-width: 599.98px) {

    .login-section {
        width: 100%;
    }

    .login-title {
        font-size: 20px;
    }
}

@media only screen and (max-width: 767.98px) {
    .login-section {
        width: 100%;
    }

    .login-title {
        font-size: 20px;
    }
}