.adresses-bg {
    background: #F3F4F6;
}

.adresses-section {
    padding: 80px 0;
}

.adresses-title {
    font-size: 28px;
    margin-bottom: 50px;
}

.adresses-data {
    display: flex;
    justify-content: space-between;
}

.adresses-logo {
    position: relative;
    text-align: center;
}

.adresses-logo-img {
    max-height: 250px;
    border-radius: 50px;
}

.adresses-logo-btn {
    all: unset;
    background: #fff;
    text-align: center;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 20%;
    border-radius: 0 0 50px 50px;
}

.adresses-logo-btn:hover {
    background: #918F8F;
    cursor: pointer;
}