@media only screen and (max-width: 767.98px) {
    .details-section {
        flex-direction: column;
        align-items: center;
        padding: 20px 0;
    }

    .cargo-history-section {
        width: 80%;
        margin: 0 auto;
    }
    .cargo-history-title, .details-title {
        font-size: 24px;
    }

    .details-data-title {
        font-size: 20px;
    }

    .details-input-wrapper {
        width: 100%;
        margin: 0;
    }
}